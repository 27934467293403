import { runWithAmplifyServerContext } from 'aws-amplify/adapter-core';

export default defineNuxtRouteMiddleware(async (to) => {
  const { $Amplify } = useNuxtApp();

  try {
    const session = await $Amplify.Auth.fetchAuthSession();
    if (session.tokens === undefined) {
      console.log('Client: User not authenticated to access ', to);
      return navigateTo('/login');
    }
  } catch (err) {
    console.log('Client: User not authenticated to access ', to);
    return navigateTo('/login');
  }
});
